import {
    getAuth,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    sendPasswordResetEmail,
    updateProfile,

} from "firebase/auth";


export default {

    // Metodo que permite registar un usuario y enviar correo para verificar UNA DIRECCION DE CORREO REAL
    async signup(email, password, displayName,photoURL) {
        let response = null
        const auth = getAuth();
        await createUserWithEmailAndPassword(
            auth,
            email,
            password
        )
            .then((userCredential) => {
                // console.log(userCredential.user);
                // Actualizar nombre de usuario (Corporate name)
                updateProfile(auth.currentUser, {
                    displayName: displayName,
                    photoURL:photoURL
                })
                // Enviar correo para verificar el correo
                sendEmailVerification(userCredential.user);
                // console.log("Usuario creado, verifique correo")
                response = userCredential.user
            })
            .catch((error) => {
                // const errorCode = error.code;
                // console.log(error);
                // console.log("Algo anda mal...");
                response = error.code
                // const errorMessage = error.message;
            });
        return response
    },


    // Metodo que permite el login del usuario (email, password) 
    async login(email, password) {
        let response = null
        const auth = getAuth();
        await signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // console.log(userCredential.user.email);
                // console.log(userCredential);
                // console.log("Login Exitoso!!")
                response = userCredential.user

            })
            .catch((error) => {
                // const errorCode = error.code;
                // console.log("Error en Login!!")
                // console.log(error.code);
                // console.log(error.message);
                response = error.code

                // const errorMessage = error.message;
            });
        return response
    },

    // Metodo para cerrar sesion del usuario
    logOut() {
        const auth = getAuth();
        signOut(auth).then(() => {
            // console.log("Sign-out successful")
        }).catch((error) => {
            // An error happened.
            console.log(error)
        });
    },
    // Metodo para verificar si un usuario esta activo (Para generar AutoLogin /login)
    async verificateLoginUser() {
        let response = null
        const auth = getAuth();
        await onAuthStateChanged(auth, (user) => {
            if (user) {
                response = 'active'
            } else {
                response = null
            }
        });
        return response
    },
    // Metodo que retorna toda la info de un usuario activo
    async infoUserActive() {
        let response = null
        const auth = getAuth();
        await onAuthStateChanged(auth, (user) => {
            if (user) {
                response = user
            } else {
                response = null
            }
        });
        return response
    },

    // Metodo que envia el correo de recuperación de contraseña
    async sendPasswordResetEmail(email) {
        let response = null
        const auth = getAuth();
        await sendPasswordResetEmail(auth, email)
            .then(() => {
                // Password reset email sent!
                // ..
            })
            .catch((error) => {
                //console.log(error.code) 
                response = error.code
                // ..
            });
        return response
    },

    // Metodo que reenvia un el correo para verificar el correo
    async resendVerificationEmail() {
        let response = null
        const auth = getAuth();

        if (auth.currentUser !== null) {
            await sendEmailVerification(auth.currentUser)
                .then(() => {
                    // console.log("Correo enviado meno")
                    response = auth.currentUser.email
                })
                .catch((error) => {
                    // console.log(error.code)
                    response = error.code
                });
            return response
        }
    },

    // Metodo para asegurar conexion y credenciales con Firebase (Solo con verificaion de correo)
    async getAuthfirebase() {
        await getAuth();
    },

    // Metodo para crear token jwt personalizado
    async createTokenJWT() {

        const auth = getAuth();
        return await auth.currentUser.getIdToken();


    },

    async updatePhotoFirebase(urlPhoto) {
        let response = null
        const auth = getAuth();
        // await updateProfile(auth.currentUser, {
        //     displayName: "meta", photoURL: "https://example.com/jane-q-user/profile.jpg"
        await updateProfile(auth.currentUser, {
            photoURL: urlPhoto
        }).then(() => {
            // Profile updated!
            // response = auth.currentUser.photoURL
            response = "success"
            // console.log(response)
            // ...
        }).catch((error) => {
            // An error occurred
            console.log(error)
            response = error.code
            // ...
        });
        return response
    },

    async updateDisplayNameFirebase(displayName) {
        let response = null
        const auth = getAuth();
        // await updateProfile(auth.currentUser, {
        //     displayName: "meta", photoURL: "https://example.com/jane-q-user/profile.jpg"
        await updateProfile(auth.currentUser, {
            displayName: displayName
        }).then(() => {
            // Profile updated!
            // response = auth.currentUser.photoURL
            response = "success"
            // console.log(response)
            // ...
        }).catch((error) => {
            // An error occurred
            console.log(error)
            response = error.code
            // ...
        });
        return response
    },

    // Metodo que permite registar un usuario y enviar correo para verificar UNA DIRECCION DE CORREO REAL
    async signupAuxiliar(email, password, displayName,photoURL) {
        let response = null
        const auth = getAuth();
        await createUserWithEmailAndPassword(
            auth,
            email,
            password
        )
            .then((userCredential) => {
                // console.log(userCredential.user);
                // Actualizar nombre de usuario (Corporate name)
                updateProfile(auth.currentUser, {
                    displayName: displayName,
                    photoURL:photoURL
                })
                // Enviar correo para verificar el correo
                // sendEmailVerification(userCredential.user);
                // Reset Password
                sendPasswordResetEmail(auth, email);
                // console.log("Usuario creado, verifique correo")
                response = userCredential.user
            })
            .catch((error) => {
                // const errorCode = error.code;
                // console.log(error);
                // console.log("Algo anda mal...");
                response = error.code
                // const errorMessage = error.message;
            });
        return response
    },

    //Metodo para verificar el token jwt +++++++++++ NO FUNCIONA TODAVIA ++++++++++++++++++
    // async verificateTokenJWT(){
    //     const auth = getAuth();

    //     const idToken = "eyJhbGciOiJSUzI1NiIsImtpZCI6ImY0ZTc2NDk3ZGE3Y2ZhOWNjMDkwZDcwZTIyNDQ2YTc0YjVjNTBhYTkiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiZGFuaWxtb3JlOSIsImlzcyI6Imh0dHBzOi8vc2VjdXJldG9rZW4uZ29vZ2xlLmNvbS9yZWJlbC1yb2JvdGljcyIsImF1ZCI6InJlYmVsLXJvYm90aWNzIiwiYXV0aF90aW1lIjoxNjUzOTc0MDQ3LCJ1c2VyX2lkIjoidE5XWDVKY1FVSFcwOVhsb3dJc0szSE1NNHA0MiIsInN1YiI6InROV1g1SmNRVUhXMDlYbG93SXNLM0hNTTRwNDIiLCJpYXQiOjE2NTM5NzQwNDcsImV4cCI6MTY1Mzk3NzY0NywiZW1haWwiOiJkYW5pbG1vcmU5OEBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsiZGFuaWxtb3JlOThAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.GWglJtXr-lXIAl_JUifWFMxa7VaoxVY7M0pL55fjflFABkiVkyVw3r-f58xr-FaZW3lBmhUmxZoXS9k5WpbUlU7pWjDagPUKXX1F_qIJEsyGNSBkcDfXEKkv7HOhsKY5KZ1KbbdSYhwkW4X3w4cUtdOIiqY9GHhuKgGWFHqQmu-pQESZR8dDlD2nWHn6qltczydaM55qnBq4njwtUVdAH48RDaPThyrRX_bqbpvny52IskndPWcxVrMBGvVVZ_lF6_zNn6PJGbOWe8DQZxB6IIuosFQuWivlmPbhgZ3BnIzWRdAvz2-g1mPHQmU9kF4EqmTlxYxtNs1VzfonuY4DXQ"

    //     auth.verifyIdToken(idToken)
    //     .then((decodedToken) => {
    //       const uid = decodedToken.uid;
    //       console.log("decodificando jwt")
    //       console.log(uid)
    //     })
    //     .catch((error) => {
    //       console.log(error)
    //     });
    // }

    // "eyJhbGciOiJSUzI1NiIsImtpZCI6ImY0ZTc2NDk3ZGE3Y2ZhOWNjMDkwZDcwZTIyNDQ2YTc0YjVjNTBhYTkiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiZGFuaWxtb3JlOSIsImlzcyI6Imh0dHBzOi8vc2VjdXJldG9rZW4uZ29vZ2xlLmNvbS9yZWJlbC1yb2JvdGljcyIsImF1ZCI6InJlYmVsLXJvYm90aWNzIiwiYXV0aF90aW1lIjoxNjUzOTc0MDQ3LCJ1c2VyX2lkIjoidE5XWDVKY1FVSFcwOVhsb3dJc0szSE1NNHA0MiIsInN1YiI6InROV1g1SmNRVUhXMDlYbG93SXNLM0hNTTRwNDIiLCJpYXQiOjE2NTM5NzQwNDcsImV4cCI6MTY1Mzk3NzY0NywiZW1haWwiOiJkYW5pbG1vcmU5OEBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsiZGFuaWxtb3JlOThAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.GWglJtXr-lXIAl_JUifWFMxa7VaoxVY7M0pL55fjflFABkiVkyVw3r-f58xr-FaZW3lBmhUmxZoXS9k5WpbUlU7pWjDagPUKXX1F_qIJEsyGNSBkcDfXEKkv7HOhsKY5KZ1KbbdSYhwkW4X3w4cUtdOIiqY9GHhuKgGWFHqQmu-pQESZR8dDlD2nWHn6qltczydaM55qnBq4njwtUVdAH48RDaPThyrRX_bqbpvny52IskndPWcxVrMBGvVVZ_lF6_zNn6PJGbOWe8DQZxB6IIuosFQuWivlmPbhgZ3BnIzWRdAvz2-g1mPHQmU9kF4EqmTlxYxtNs1VzfonuY4DXQ"

}


