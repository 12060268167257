<template>
  <v-app>
        <v-col>
          <div style="padding: 80px 0px 0px">
          <router-link to="/">
            <v-img class="mx-auto mb-3 mt-5" src='https://d1kz8c63g8kbpa.cloudfront.net/web/chatpro_white_bg.png' max-height="250"
              max-width="200" contain></v-img>
          <br>
          </router-link>

          <v-card class="rounded-lg elevation-3 mx-auto mx-2" max-width="500">
            <center>
              <br>
              <h2 style="text-align: left;" class="ml-8 mt-4 mb-1">Sign in to your account</h2>
            </center>
            <v-card-text>
              <form ref="form" @submit.prevent="login()" class="mx-3">

                <p style="color:black; text-align: left" class="ml-3"><strong>Email</strong></p>
                <v-text-field v-model="email" name="email" type="email"
                   hint="Email" required  outlined></v-text-field>
                <v-row>
                  <v-col>
                    <p style="color:black; text-align: left" class="ml-2"><strong>Password</strong></p>
                  </v-col>
                  <v-col>
                    <p style="text-align: right"><a v-on:click="goToResetPassword()" style="text-decoration: none;"><b>Forget your password?</b></a></p>
                  </v-col>
                </v-row>
               
                <v-text-field :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" v-model="password"
                  name="password"  :type="show ? 'text' : 'password'" 
                  @click:append="show = !show" required  outlined></v-text-field>
                <center>
                  <v-btn  type="submit" class="white--text mt-4 mb-4" :loading="loadingLogin" :disabled="loadingLogin" rounded color="black" width="150"> Login
                  </v-btn>
                </center>
                <p v-if="error" class="error" justify-center>
                  incorrect User or Password.
                </p>
              </form>
            </v-card-text>
          </v-card>
          <br>
          <center>
            <br>
            <a style="color:black">Create an account! </a><a v-on:click="goToRegister()" style="text-decoration: underline;">Sign Up</a>
          </center>
          <br />
          </div>
        </v-col>
  </v-app>
</template>


<script>
//7579E7 color
import authFirebase from "@/logic/authFirebase"; // Importar libreria donde estan todos los metodos auht Firebase
// import alerts from "@/logic/alerts"; // Importar libreria donde estan todos los metodos auht Firebase

import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);


export default {
  name: "Login-",
  data: () => ({

    colors: [
      'indigo',
      'warning',
      'pink darken-2',
      'red lighten-1',
      'deep-purple accent-4',
    ],
    slides: [
      'First',
      'Second',
      'Third',
      'Fourth',
      'Fifth',
    ],
    email: "",
    password: "",
    error: false,
    show: false,
    loadingLogin: false,
  }),
  async created() {

    // Middleware para autologin si hay sesion activa
    const response = await authFirebase.verificateLoginUser()
    if (response == 'active') {
      this.$router.push("/Dashboard/main")
    }

  },
  methods: {
    goToRegister() {
      this.$router.push("/Register") //redirecciona a chatpro landing
    },
    goToResetPassword() {
      this.$router.push("/PasswordRecovery")
    },
    //++++++++++++++++++++++ Metodo para Login llamado al hacer click al boton de Login +++++++++++++++++++
    async login() {
      this.loadingLogin=true; //activa loader del boton de login
      const response = await authFirebase.login(this.email, this.password);
      // console.log(response);

      // const token = await authFirebase.createTokenJWT();

      // Condiciones de fallo escritura email, password o usuario no registrado
      if (
        response == "auth/wrong-password" ||
        response == "auth/user-not-found"
      ) {
        // console.log("Por favor revisar Credeciales o usuario no rigistrado");
        // alerts.alertLoginCredentials(this.$alert)
        Vue.$toast.open({
            message: '<p style="font-family: roboto;">Wrong username or password!</p>',
            type: 'error',
            position: 'top',
            duration: 6000
        });
        this.loadingLogin=false; //desactiva loader del boton de login
      } else {
        // Condicion para verificar que el correo este verificado
        if (response.emailVerified) {

          // Alerta de correo verificado y tambien direcciona al /dashboard
          // alerts.alertEmailVerificated(this.$fire, response.email, 3500, this.$router, "/dashboard/main")
          Vue.$toast.open({
            message: '<p style="font-family: roboto;">Welcome!</p>',
            type: 'success',
            position: 'top',
            duration: 4000
        });
        this.loadingLogin=false; //desactiva loader del boton de login
        this.$router.push("/dashboard/main")
        } else {
          // Alerta de correo NO verificado
          // alerts.alertEmailNOVerificated(this.$alert, response.email)
          Vue.$toast.open({
            message: '<p style="font-family: roboto;">Check your inbox and <strong>verify</strong> your email.</p>',
            type: 'warning',
            position: 'top',
            duration: 8000
        });
        this.loadingLogin=false; //desactiva loader del boton de login
        }
      }
    },
  },
};
</script>

<style>
.v-btn {
  text-transform: none;
}

.swal2-popup {
  font-family: 'roboto' !important;
}
</style>