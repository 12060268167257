import Vue from 'vue'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Router from 'vue-router'

//Empresas
import Login from '../views/EmpresasB2B/Login.vue'
import passwordRecovery from '../views/EmpresasB2B/passwordRecovery.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    //Dashboard
    {
      path: "/Dashboard",
      component: () => import('../views/Dashboard/Dashboard.vue'),
      children: [
        { path: "/Dashboard/Account/", component: () => import('../views/Dashboard/Account.vue') },
        { path: "/Dashboard/main", component: () => import('../views/Dashboard/main.vue') },
        { path: "/Dashboard/create/select", component: () => import('../views/Dashboard/createSelect.vue')},

        { path: "/Dashboard/create", component: () => import('../views/Dashboard/createSelect.vue')},
        { path: "/Dashboard/create_new", component: () => import('../views/Dashboard/createBot.vue')},

        //Text
        { path: "/Dashboard/create/text", component: () => import('../views/Dashboard/createBotText.vue')},
        { path: "/Dashboard/create/pdf", component: () => import('../views/Dashboard/createBotPDF.vue')},

        //PDF
        { path: "/Dashboard/edit/pdf/:id_chatbot", component: () => import('../views/Dashboard/editCreateBotPDF.vue')},
        { path: "/Dashboard/edit/text/:id_chatbot", component: () => import('../views/Dashboard/editCreateBotText.vue')},


        //Full Bot
        { path: "/Dashboard/:id_chatbot", component: () => import('../views/Dashboard/fullBot.vue')},
        { path: "/Dashboard/edit/custom/:id_chatbot", component: () => import('../views/Dashboard/customizeBot.vue')},
        { path: "/Dashboard/edit/sources/:id_chatbot", component: () => import('../views/Dashboard/editTrainingData.vue')},



      ],
      meta: {
        requiresAuth: true
      }
    },

    {
      path: '/',
      name: 'landing',
      beforeEnter() {
        window.location.href = "https://chatpro.pro";
      }
    },
    //Login
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    //Password Recovery
    {
      path: '/PasswordRecovery',
      name: 'PasswordRecovery',
      component: passwordRecovery
    },
    {
      path: '/Register',
      name: 'register',
      beforeEnter() {
        window.location.href = "https://chatpro.pro/register";
      }
    },
    
  ]
});


// ++++++++++++++++++++ Middleware antes que se ejecuten las solicitudes de las rutas +++++++++++++++++++++++
router.beforeEach((to,from,next)=> {

  if(to.matched.some(route => route.meta.requiresAuth)){
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {  // Metodo para verificar si hay usuario Logueado y dejalo pasar a la ruta (next())
      if (user) {

        if(user.emailVerified){
          // console.log(user.emailVerified)
          next();
        }else{
          next({
            name: 'login'
          })
        }

      } else {
        next({
          name: 'login'
        })
      }
    });
  }else{
    next()
  }

})

export default router;